<template>
  <div class="bg bg6">
    <div class="class--con">
      <class-but @forward="forward"/>
      <div class="class--con__title1" v-if="courseMasterWorkList.courseNames">
        <div style="font-size: 45px">2024暑秋核心课程说明</div>
        <span style="font-size: 24px;" v-for="(i,k) in courseMasterWorkList.courseNames.slice(0,5)"
              :key="k">{{ i }}、</span>
      </div>
      <div class="class--container">
        <div style="display: flex">
          <img class="class--con__titleImg" :src="courseMasterWorkList.imgUrl" alt="">
          <div class="class--con__top">
            <!--            <span class="iocn"></span>-->
            <!--            <span class="class&#45;&#45;con&#45;&#45;top__title">发展特点：</span>-->
            <span class="class--con--top__content">{{ courseMasterWorkList.tip2 }}</span>
          </div>
<!--          <div class="class&#45;&#45;con__top">-->
<!--            &lt;!&ndash;            <span class="iocn"></span>&ndash;&gt;-->
<!--            &lt;!&ndash;            <span class="class&#45;&#45;con&#45;&#45;top__title">发展特点：</span>&ndash;&gt;-->
<!--            <span class="class&#45;&#45;con&#45;&#45;top__content">{{ courseMasterWorkList.tip3 }}</span>-->
<!--          </div>-->
        </div>
        <div class="class--con__border">
          <div class="class--con__bottom" v-for="(i,k) in courseMasterWorkList.tip4s" :key="k">
            <span class="iocn"></span>
            <span class="class--con__bottom__title">{{ i.var1 }}：</span>
            <span class="class--con__bottom__content">{{ i.var2 }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut.vue";
import service from "@/utils/axios";

export default {
  name: "sixteenPage",
  components: {ClassBut},
  data() {
    return {

      courseMasterWorkList: {}
    }
  },
  created() {
    this.gitApicourseMasterWork()
  },
  methods: {
    forward() {
      this.$router.push({
        name: 'PageSeventeenth',
        query: this.$route.query,
      })
    },
    async gitApicourseMasterWork() {
      let res = await service.post(`/dwart/an_teaching/hbk/v1/search/courseMsgInfo`, {
        classId: this.$route.query.classId
      })
      this.courseMasterWorkList = res
    },
  },
}
</script>

<style lang="less" scoped>
@import "reportClass.less";

.class--container {
  padding: 0px 90px;
  box-sizing: border-box;
  color: #2B3654;
  font-size: 24px;

  .class--con__titleImg {
    width: 706px;
    height: 529px;
    border-radius: 10px;
    border: 5px solid #FFC94D;
    object-fit: contain;
  }

  .class--con__bottom {
    width: 100%;
    height: 60px;
    /*line-height: 60px;*/
    /*overflow:hidden;*/
    /*white-space:nowrap;*/
    /*text-overflow:ellipsis;*/

    .class--con__bottom__title {
      color: #2B3654;
      font-size: 24px;
      font-weight: bold;
    }

    .class--con__bottom__content {
      color: #666666;
      font-size: 22px;
    }
  }

  .iocn {
    width: 16px;
    height: 16px;
    background: #5BCD98;
    border-radius: 50%;
    display: inline-block;
  }

  .class--con__top {
    overflow-y: auto;
    width: 100%;
    height: 525px;
    padding: 10px;
    box-sizing: border-box;

    .class--con--top__title {
      font-size: 24px;
      color: #2B3654;
      font-weight: Bold;
    }

    .class--con--top__content {
      color: #666666;
      font-size: 24px;
      line-height: 40px;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

.class--con__border {
  height: 180px;
  overflow-y: auto;
}

.class--con__title1 {
  color: #2B3654;
  font-weight: bold;
  margin-left: 90px;
  margin-top: 45px;
  margin-bottom: 20px;
}
</style>
